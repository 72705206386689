
import {Options, Vue} from 'vue-class-component';
import {App, Format} from '@/services';
import {GetUser} from '@/model/tokenGetUser';
import {ElCol, ElRow, ElDatePicker, ElNotification} from 'element-plus';
import card from './card.vue';
import Password from '@/web/operation/security/password/index.vue';
import Phone from '@/web/operation/security/phone/index.vue';
import CardPanel from '@/web/operation/security/bankcard/index.vue';

@Options({
  components: {
    ElCol,
    ElRow,
    ElDatePicker,
    card,
    Password,
    Phone,
    CardPanel
  }
})
export default class PersonalInformation extends Vue {
  config = App.getConfig() || {
    dollarSign: '¥'
  };
  userInfo = App.getUserinfo();

  contactMethods = [
    {
      name: 'phoneNumber',
      text: 'ui_mobile',
      page: '1'
    },
    // {
    //   name: 'email',
    //   text: 'ui_mailbox',
    //   verified: '$data.$_authMode.email',
    //   page: '2'
    // },
    {
      name: 'password',
      text: 'ui_login_pwd',
      page: '3'
    }
  ];
  pickerBirthdayBefore = (time: Date) => {
    // time.getTime() >= beginDateVal false 可用，true 不可用
    return time.getTime() >= Date.now() - 8.64e7; //今天及以后的日期都不能选
  };
  panel = {
    top: [
      {
        text: 'ui_user_acc_colon',
        key: 'userAccount'
      },
      ...(this.config.dollarSign !== '¥'
        ? this.config.dollarSign === '₫'
          ? [
              {
                text: 'ui_last_name',
                key: 'lastName'
              },
              {
                text: 'ui_first_name',
                key: 'firstName'
              }
            ]
          : [
              {
                text: 'ui_first_name',
                key: 'firstName'
              },
              {
                text: 'ui_last_name',
                key: 'lastName'
              }
            ]
        : [
            {
              text: 'ui_real_name',
              key: 'realName'
            }
          ]),
      {
        text: 'ui_user_birthday',
        key: 'birth'
      }
    ]
  };
  info = {
    realName: '',
    firstName: '',
    lastName: '',
    tempFirstName: '',
    tempLastName: '',
    showNickname: false,
    birth: new Date(),
    loading: false,
    birthDate: '',
    show: false,
    maxDate: new Date(),
    minDate: new Date('1899/01/01')
  };

  MailModal = false;
  phoneModal = false;
  passwordModal = false;

  get showModel() {
    return this.MailModal || this.phoneModal || this.passwordModal;
  }

  async mounted() {
    // if (!this.userInfo) {
    await GetUser.action();
    this.userInfo = App.getUserinfo();
    // }
  }
  checkShow() {
    return this.config.dollarSign === '¥'
      ? !this.userInfo?.realName || !this.userInfo?.birth
      : (!this.userInfo?.lastName && !this.userInfo?.firstName) || !this.userInfo?.birth;
  }
  async modifyNickname() {
    this.info.loading = true;
    let params = {};
    if (this.config.dollarSign === '¥' && this.info.realName && !this.userInfo?.realName) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      params = {...params, real_name: this.info.realName};
    }
    if (
      this.config.dollarSign !== '¥' &&
      this.info.firstName &&
      this.info.lastName &&
      !this.info.tempFirstName &&
      !this.info.tempLastName
    ) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      params = {...params, first_name: this.info.firstName, last_name: this.info.lastName};
    }
    if (this.info.birthDate && !this.userInfo?.birth) {
      params = {...params, birth: this.info.birthDate};
    }
    if (!this.info.birthDate) {
      if (this.config.dollarSign === '¥' && (!this.info.realName || !this.userInfo?.realName))
        return;
      if (
        this.config.dollarSign !== '¥' &&
        ((!this.info.firstName && !this.info.lastName) ||
          (!this.userInfo?.firstName && !this.userInfo?.lastName))
      )
        return;
      params = {...params, birth: this.info.birthDate};
    }

    const {message} = await GetUser.edit(params);
    this.info.loading = false;
    this.info.showNickname = false;
    if (message) {
      ElNotification({type: 'info', message});
      await GetUser.action();
      this.userInfo = App.getUserinfo();
    }
  }
  goToLink(name: string) {
    this.$router.push({name});
  }
  openChat() {
    //打開客服
    // this.$root.$emit('openchat', true);
    // this.GameModel = false;
  }
  switchedit(e: string) {
    const _e = parseInt(e);
    switch (_e) {
      case 2:
        this.MailModal = true;
        break;
      case 1:
        this.phoneModal = true;
        break;
      case 3:
        this.passwordModal = true;
        break;
      default:
    }
  }
  Closepop() {
    this.MailModal = false;
    this.phoneModal = false;
    this.passwordModal = false;
  }
}
