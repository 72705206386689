<template>
  <el-row type="flex" justify="space-between">
    <el-col class="u_p--l10 u_p--r10 right-line" :span="15">
      <div class="static-container animated fadeIn info">
        <div class="static-container-header static-container-header--small">
          <h5 class="static-container-header__title">
            <div class="static-container-header__left">
              {{ $t('personal_information') }}
            </div>
            <div class="static-container-header__right"></div>
          </h5>
        </div>
      </div>
      <div class="static-container-body">
        <div class="static-container-header static-container-header--small">
          <div class="flex flex-justify-between u_m--t50 u_m--r20">
            <card
              :title="$t('ui_cumulative_discount_amount')"
              :value="userInfo.rewardPrice"
              @click="goToLink({name: 'UserFundReport'})"
              class="u_m--r12"
            />
            <card
              class="u_m--l12"
              :title="$t('ui_monthly_discount_amount')"
              :value="userInfo.rewardMonth"
              @click="goToLink({name: 'UserFundReport'})"
            />
          </div>
          <!-- 標題 -->
        </div>
        <!-- <div class="static-container-header static-container-header--small">
          <h5 class="static-container-header__title">
            <div class="static-container-header__left">
              {{ $t('ui_basic_info') }}
            </div>
            <div class="static-container-header__right"></div>
          </h5>
        </div> -->
        <!-- <div class="el-form u_m--b20">
          <div v-if="config.dollarSign != '¥'" :class="nowLang != 'zh-CN' ? 'reverse' : ''">
            <div class="el-form-item el-form-item--small">
              <label class="el-form-item__label"> {{ $t('ui_last_name') }}： </label>
              <div
                class="el-form-item__content"
                style="margin-left: 1rem"
                v-if="!userInfo.realName"
              >
                <div class="user-info__item-main">
                  <div
                    class="user-info__item-input el-input el-input--medium c_input--longest"
                    :class="nowLang == 'en' ? 'u_m--l5' : ''"
                  >
                    <div class="el-input__center">
                      <input
                        type="text"
                        :placeholder="$t('ui_input_last_name')"
                        name="new_realName"
                        v-model.trim="info.lastName"
                        class="el-input__inner"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="el-form-item__content plain"
                style="margin-left: 1rem"
                v-if="userInfo.realName"
              >
                {{ userInfo.lastName }}
              </div>
            </div>
            <div class="el-form-item el-form-item--small">
              <label class="el-form-item__label"> {{ $t('ui_first_name') }}： </label>
              <div
                class="el-form-item__content"
                style="margin-left: 1rem"
                v-if="!userInfo.realName"
              >
                <div class="user-info__item-main">
                  <div
                    class="user-info__item-input el-input el-input--medium c_input--longest "
                    :class="nowLang == 'en' ? 'u_m--l5' : ''"
                  >
                    <div class="el-input__center">
                      <input
                        type="text"
                        :placeholder="$t('ui_input_first_name')"
                        name="new_realName"
                        v-model.trim="info.firstName"
                        class="el-input__inner"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="el-form-item__content plain"
                style="margin-left: 1rem"
                v-if="userInfo.realName"
              >
                {{ userInfo.firstName }}
              </div>
            </div>
          </div>
          <div class="el-form-item el-form-item--small" v-else>
            <label class="el-form-item__label"> {{ $t('ui_real_name') }}： </label>
            <div class="el-form-item__content" style="margin-left: 1rem" v-if="!userInfo.realName">
              <div class="user-info__item-main">
                <div
                  class="user-info__item-input el-input el-input--medium c_input--longest "
                  :class="nowLang == 'en' ? 'u_m--l5' : ''"
                >
                  <div class="el-input__center">
                    <input
                      type="text"
                      :placeholder="$t('ui_input_name')"
                      name="new_realName"
                      v-model.trim="info.realName"
                      class="el-input__inner"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              class="el-form-item__content plain"
              style="margin-left: 1rem"
              v-if="userInfo.realName"
            >
              {{ userInfo.realName }}
            </div>
          </div>

          <div class="el-form-item el-form-item--small">
            <label class="el-form-item__label"> {{ $t('ui_user_birthday') }}： </label>
            <div class="el-form-item__content " style="margin-left: 1rem" v-if="!userInfo.birth">
              <el-date-picker
                v-model="info.birthDate"
                type="date"
                :placeholder="$t('ui_select')"
                :disabled-date="pickerBirthdayBefore"
                class="c_input--longest"
                format="YYYY-MM-DD"
              >
              </el-date-picker>
            </div>
            <div
              class="el-form-item__content plain"
              style="margin-left: 1rem"
              v-if="userInfo.birth"
            >
              {{ userInfo.birth }}
            </div>
          </div>
          <div class="el-form-item el-form-item--small">
            <label class="el-form-item__label"> {{ $t('rp_reg_date') }}： </label>
            <div class="el-form-item__content" style="margin-left: 1rem; line-height: 40px">
              {{ userInfo.createTime.substr(0, 10) }}
            </div>
          </div>
          <div
            class="el-form-item el-form-item--small text-tomato flex flex-column "
            v-if="!userInfo.realName || !userInfo.birth"
          >
            <div class="flex flex-row">
              <i class="el-icon-warning "></i>
              <p style="width: calc(100% - 14px);" class="u_m--l5">
                <span>
                  {{ $t('sysmsg_info_check_service') }}
                </span>
                <span class="online_cs u_c--pointer" @click="openChat">
                  {{ $t('ui_online_cs') }}
                </span>
                <span>
                  {{ nowLang === 'zh-CN' ? '。' : '.' }}
                </span>
              </p>
            </div>
            <div class="flex flex-row">
              <i class="el-icon-warning "></i>
              <p style="width: calc(100% - 14px);" class="u_m--l5">
                <span>
                  {{ $t('sysmsg_bind_bank_same_name') }} {{ nowLang === 'zh-CN' ? '。' : '.' }}
                </span>
              </p>
            </div>
          </div>
          <div
            class="el-form-item el-form-item--small text-tomato flex flex-column"
            v-if="userInfo.realName && userInfo.birth"
          >
            <div class="flex flex-row">
              <i class="el-icon-warning "></i>
              <p style="width: calc(100% - 14px);" class="u_m--l5">
                <span>
                  {{ $t('sysmsg_info_check_service') }}
                </span>
                <span class="online_cs u_c--pointer" @click="openChat">
                  {{ $t('ui_online_cs') }}
                </span>
                <span>
                  {{ nowLang === 'zh-CN' ? '。' : '.' }}
                </span>
              </p>
            </div>
          </div>
          <div
            class="flex u_m--l1rem flex-justify-center"
            v-if="!userInfo.realName || !userInfo.birth"
          >
            <button
              type="button"
              class="c_button--huge j_btn-txt--primary userbtn-bg--submit"
              @click.stop.prevent="modifyNickname"
              :disabled="
                (config.dollarSign === '¥' && !info.birthDate && !info.realName) ||
                  (config.dollarSign !== '¥' &&
                    !info.firstName &&
                    !info.lastName &&
                    !info.birthDate)
              "
            >
              <span class="c_button__text">{{ $t('btn_sent_out') }}</span>
            </button>
          </div>
        </div> -->
        <div class="static-container-header static-container-header--small">
          <h5 class="static-container-header__title">
            <div class="static-container-header__left">
              {{ $t('ui_account_security') }}
            </div>
            <div class="static-container-header__right"></div>
          </h5>
        </div>
        <div class="el-form-item--small u_d--table user-info__form">
          <template v-for="item in contactMethods" :key="item.text">
            <div class="table-row">
              <div class="table-cell">{{ $t(item.text) }}：</div>
              <div class="el-form-item__content table-cell">
                {{
                  userInfo[item.name]?.text === null || userInfo[item.name]?.text === ''
                    ? $t('sysmsg_input_null')
                    : userInfo[item.name]?.text || '**********'
                }}
              </div>
              <div class="table-cell">
                <button
                  type="button"
                  class="u_main--btn j_btn-txt--primary  btn_size  u_m--l50"
                  @click="switchedit(item.page)"
                >
                  <span class="u_c--pointer">
                    {{
                      userInfo[item.name]?.text || item.name == 'password'
                        ? $t('btn_modi')
                        : $t('btn_add')
                    }}
                  </span>
                </button>
              </div>
            </div>
          </template>
        </div>
      </div>
    </el-col>
    <el-col :span="9">
      <card-panel class="u_p--l30 u_p--r10" />
    </el-col>
    <el-dialog
      v-model="showModel"
      :show-close="false"
      center
      :close-on-click-modal="false"
      width="600px"
    >
      <template #title>
        <div class="dialog_head">
          <div style="margin:auto;" class="u_fw--bold">{{ $t('ui_account_security') }}</div>
          <button
            type="button"
            aria-label="Close"
            class="el-dialog__headerbtn header_close"
            @click="Closepop"
          >
            <svg
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <g id="icon/24/X" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g
                  id="编组"
                  mask="url(#mask-2)"
                  stroke="#333333"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                >
                  <g
                    transform="translate(11.500000, 12.500000) rotate(-315.000000) translate(-11.500000, -12.500000) translate(0.000000, 1.000000)"
                  >
                    <line x1="0" y1="11.5" x2="23" y2="11.5" id="路径-18"></line>
                    <line x1="11.5" y1="0" x2="11.5" y2="23" id="路径-19"></line>
                  </g>
                </g>
              </g>
            </svg>
          </button>
        </div>
      </template>
      <div class="dialog_main" v-if="phoneModal">
        <Phone @close="Closepop" />
      </div>
      <div class="dialog_main" v-if="MailModal">
        <!-- <UserEmail @close="Closepop" /> -->
      </div>
      <div class="dialog_main" v-if="passwordModal">
        <Password @close="Closepop" />
      </div>
    </el-dialog>
  </el-row>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import {App, Format} from '@/services';
import {GetUser} from '@/model/tokenGetUser';
import {ElCol, ElRow, ElDatePicker, ElNotification} from 'element-plus';
import card from './card.vue';
import Password from '@/web/operation/security/password/index.vue';
import Phone from '@/web/operation/security/phone/index.vue';
import CardPanel from '@/web/operation/security/bankcard/index.vue';

@Options({
  components: {
    ElCol,
    ElRow,
    ElDatePicker,
    card,
    Password,
    Phone,
    CardPanel
  }
})
export default class PersonalInformation extends Vue {
  config = App.getConfig() || {
    dollarSign: '¥'
  };
  userInfo = App.getUserinfo();

  contactMethods = [
    {
      name: 'phoneNumber',
      text: 'ui_mobile',
      page: '1'
    },
    // {
    //   name: 'email',
    //   text: 'ui_mailbox',
    //   verified: '$data.$_authMode.email',
    //   page: '2'
    // },
    {
      name: 'password',
      text: 'ui_login_pwd',
      page: '3'
    }
  ];
  pickerBirthdayBefore = (time: Date) => {
    // time.getTime() >= beginDateVal false 可用，true 不可用
    return time.getTime() >= Date.now() - 8.64e7; //今天及以后的日期都不能选
  };
  panel = {
    top: [
      {
        text: 'ui_user_acc_colon',
        key: 'userAccount'
      },
      ...(this.config.dollarSign !== '¥'
        ? this.config.dollarSign === '₫'
          ? [
              {
                text: 'ui_last_name',
                key: 'lastName'
              },
              {
                text: 'ui_first_name',
                key: 'firstName'
              }
            ]
          : [
              {
                text: 'ui_first_name',
                key: 'firstName'
              },
              {
                text: 'ui_last_name',
                key: 'lastName'
              }
            ]
        : [
            {
              text: 'ui_real_name',
              key: 'realName'
            }
          ]),
      {
        text: 'ui_user_birthday',
        key: 'birth'
      }
    ]
  };
  info = {
    realName: '',
    firstName: '',
    lastName: '',
    tempFirstName: '',
    tempLastName: '',
    showNickname: false,
    birth: new Date(),
    loading: false,
    birthDate: '',
    show: false,
    maxDate: new Date(),
    minDate: new Date('1899/01/01')
  };

  MailModal = false;
  phoneModal = false;
  passwordModal = false;

  get showModel() {
    return this.MailModal || this.phoneModal || this.passwordModal;
  }

  async mounted() {
    // if (!this.userInfo) {
    await GetUser.action();
    this.userInfo = App.getUserinfo();
    // }
  }
  checkShow() {
    return this.config.dollarSign === '¥'
      ? !this.userInfo?.realName || !this.userInfo?.birth
      : (!this.userInfo?.lastName && !this.userInfo?.firstName) || !this.userInfo?.birth;
  }
  async modifyNickname() {
    this.info.loading = true;
    let params = {};
    if (this.config.dollarSign === '¥' && this.info.realName && !this.userInfo?.realName) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      params = {...params, real_name: this.info.realName};
    }
    if (
      this.config.dollarSign !== '¥' &&
      this.info.firstName &&
      this.info.lastName &&
      !this.info.tempFirstName &&
      !this.info.tempLastName
    ) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      params = {...params, first_name: this.info.firstName, last_name: this.info.lastName};
    }
    if (this.info.birthDate && !this.userInfo?.birth) {
      params = {...params, birth: this.info.birthDate};
    }
    if (!this.info.birthDate) {
      if (this.config.dollarSign === '¥' && (!this.info.realName || !this.userInfo?.realName))
        return;
      if (
        this.config.dollarSign !== '¥' &&
        ((!this.info.firstName && !this.info.lastName) ||
          (!this.userInfo?.firstName && !this.userInfo?.lastName))
      )
        return;
      params = {...params, birth: this.info.birthDate};
    }

    const {message} = await GetUser.edit(params);
    this.info.loading = false;
    this.info.showNickname = false;
    if (message) {
      ElNotification({type: 'info', message});
      await GetUser.action();
      this.userInfo = App.getUserinfo();
    }
  }
  goToLink(name: string) {
    this.$router.push({name});
  }
  openChat() {
    //打開客服
    // this.$root.$emit('openchat', true);
    // this.GameModel = false;
  }
  switchedit(e: string) {
    const _e = parseInt(e);
    switch (_e) {
      case 2:
        this.MailModal = true;
        break;
      case 1:
        this.phoneModal = true;
        break;
      case 3:
        this.passwordModal = true;
        break;
      default:
    }
  }
  Closepop() {
    this.MailModal = false;
    this.phoneModal = false;
    this.passwordModal = false;
  }
}
</script>
<style lang="scss" scoped>
.u_p--l10 {
  padding-left: 10px;
}
.u_p--r10 {
  padding-right: 10px;
}
.main-btn {
  width: 100%;
  margin: auto;
  height: 1.92rem;
  color: $cf;
  background-color: $bg;
  border-radius: 8px;
  font-size: 0.64rem;
  border-width: 0;
}

.static-container-header {
  margin-bottom: 30px;
}
.static-container-header--small .static-container-header__title {
  font-size: 24px;
  margin-bottom: 20px;
  text-transform: capitalize;
}

.el-form,
.panel {
  max-width: 95%;
}
.el-form-item--small {
  line-height: 20px;
  .el-form-item__content.plain {
    line-height: 38px;
  }
}
.el-form-item__label {
  text-align: left;
  min-width: 125px;
  vertical-align: middle;
  float: left;
  font-size: 14px;
  color: #000;
  line-height: 40px;
  padding: 0 6px 0 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.static-container-header__title::before {
  content: none;
  right: 34.3%;
  width: 70.3%;
}

.el-input--medium {
  height: 0.4rem;
  line-height: 0.4rem;
  vertical-align: text-top;
}
.el-form-item__content {
  line-height: 20px;
}
.nowrap {
  white-space: nowrap;
}

.el-button + .el-button {
  margin-left: 0;
}

:deep(.el-date-editor.el-input) {
  width: 340px;

  .el-input__inner::placeholder {
    color: #c0c4cc;
  }

  &.c_input--longest .el-input__inner:valid {
    border-color: transparent;
    color: #666666;
  }
  &.c_input--longest .el-input__inner {
    background-color: #f5f5f5;
  }
}
.reverse {
  display: flex;
  flex-direction: column-reverse;
}
.c_input--longest {
  width: 340px;
}
.u_m--t50 {
  margin-top: 50px;
}
.u_m--r20 {
  margin-right: 20px;
}
.u_m--r12 {
  margin-right: 12px;
}
.u_m--l12 {
  margin-left: 12px;
}
.c_input--longest .el-input__inner:valid {
  border-color: transparent;
  color: #666666;
}
.c_input--longest .el-input__inner {
  background-color: #f5f5f5;
}
.el-input--medium .el-input__inner {
  height: 36px;
  line-height: 36px;
}
.el-input__inner::placeholder {
  color: #c0c4cc;
}
.userbtn-bg--submit {
  background-color: $bg;
  width: calc(100% - 20px);
  font-weight: 700 !important;
}
.userbtn-bg--submit:hover {
  opacity: 0.5;
}
.userbtn-bg--submit:disabled {
  background-color: #e8e8e8;
  background-image: linear-gradient(to left, #e8e8e8, #e8e8e8);
  color: #999999;
  cursor: not-allowed;
}
[class*='c_button']:not(.c_button__text)[class*='--huge'] {
  font-size: 18px;
  line-height: 25px;
  padding: 4px 12px;
}
[class*='c_button']:not(.c_button__text) {
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
  font: 14px 'Open Sans', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, 'PingFang SC',
    'Hiragino Sans GB', 'Heiti SC', 'WenQuanYi Micro Hei', sans-serif;
  line-height: 19px;
  overflow: hidden;
  padding: 8px 25px;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: middle;
  -webkit-box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}
[class*='c_button']:not(.c_button__text) .c_button__text {
  position: relative;
  z-index: 5;
}
.c_button__text {
  font-size: 12px;
}
.j_btn-txt--primary {
  color: #ffffff;
}
.u_m--l5 {
  margin-left: 5px;
}
.u_p--l30 {
  padding-left: 30px;
}
.u_m--r30 {
  margin-right: 30px;
}
.u_m--b20 {
  margin-bottom: 20px;
}
.online_cs {
  color: #0047ab;
  text-decoration: underline;
  margin-left: 5px;
}
.u_c--pointer {
  cursor: pointer;
}
.table-row {
  display: table-row;
  border: solid 1px #ff0000;
}

.table-cell {
  display: table-cell;
  vertical-align: middle;
  padding-top: 8px;
  padding-bottom: 8px;
  white-space: nowrap;
  min-width: 90px;
}
.el-form-item__content {
  line-height: 20px;
  position: relative;
  font-size: 14px;
}
.el-form-item__content::before,
.el-form-item__content::after {
  display: table;
  content: '';
}
.btn_size {
  height: 32px;
  width: 82px;
}
.u_main--btn {
  -webkit-box-shadow: 0px 3px transparent;
  box-shadow: 0px 3px transparent;
  font-size: 0.14rem;
  border-radius: 8px;
  background-color: #1f55f0;
  color: #ffffff;
  cursor: pointer;
}
.u_d--table {
  min-width: 80%;
  display: table;
}
.user-info__form {
  margin-top: 30px;
  width: 95%;
}

.dialog_main {
  display: flex;
  align-items: center;
  justify-content: center;
}
.dialog_head {
  font-size: 24px;
  font-weight: 400;
}
.right-line {
  position: relative;
  &::after {
    content: '';
    height: 100%;
    min-height: calc(100vh - 80px);
    width: 1px;
    position: absolute;
    display: block;
    right: 0;
    top: 10px;
    border: 1px dashed #b9b8b8;
    // background-color: #b9b8b8;
  }
}
</style>
