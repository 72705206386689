<template>
  <div class="userinfo_scoreBox flex flex-column flex-center u_c--pointer" @Click="onClick">
    <span class="userinfo_score_title u_text--center">{{ $t(title) }}</span>
    <span class="userinfo_score u_text--center">
      <div class="userinfo_score_text">{{ value }}</div>
      <p class="u_p--t10 u_m--l5" v-if="showUnit">
        {{ getUnit('ui_dollar') }}
      </p>
    </span>
  </div>
</template>
<script>
const unitMap = new Map([
  ['USD', 'currency_code[2]'],
  ['VND', 'currency_code[1]'],
  ['THB', 'ui_dollar_thb'],
  ['CNY', 'currency_code[0]']
]);
export default {
  name: 'card',
  props: {
    title: {
      type: String,
      require: true
    },
    value: {
      type: [String, Number],
      default: 0
    },
    showUnit: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      unitMap
    };
  },
  methods: {
    onClick() {
      this.$emit('click', true);
    },
    getUnit(key) {
      const code = this.$root.config.currency ? this.$root.config.currency.code : 'CNY';
      return this.$t(key, {dollar: code});
    }
  }
};
</script>

<style lang="scss" scoped>
.userinfo_score_title {
  width: 100%;
  background: #c5d5ff;
  color: #000000;
  height: 100%;
  border-radius: 10px 10px 0 0;
  padding: 6px 0;
  flex: 1;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.userinfo_scoreBox {
  width: 100%;
  height: 100px;
  border: 1px #cccccc solid;
  border-radius: 10px;
  border-top: none;
}
.userinfo_score {
  width: 100%;
  flex: 2;
  padding: 12px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}
.u_c--pointer {
  cursor: pointer;
}
.u_text--center {
  text-align: center;
}
.userinfo_score_text {
  color: #ffcf65;
  font: 28px 'Open Sans', 'Roboto', sans-serif;
  font-weight: 700;
}
.u_p--t10 {
  padding-top: 10px;
}
.u_m--l5 {
  margin-left: 5px;
}
</style>
