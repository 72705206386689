<template>
  <div class="password-container">
    <div class="form__field">
      <div class="gy_form__title">{{ $t(inputList[0].text) }}：</div>
      <div>
        <el-input
          class="c_input--longest"
          :placeholder="$t(inputList[0].placeholder)"
          type="password"
          @keydown="$event.keyCode === 13 ? $event.preventDefault() : false"
          name="old_password"
          v-model="inputList[0].input"
          @blur="checkValidte(inputList[0])"
          :class="{note: errors.old}"
        ></el-input>
        <div v-if="errors.old">
          <i class="el-icon-warning text-tomato"></i>
          <span class="text-tomato">{{ $t('sysmsg_pwd_err') }}</span>
        </div>
      </div>
    </div>
    <div class="form__field">
      <div class="gy_form__title">{{ $t(inputList[1].text) }}：</div>
      <div>
        <el-input
          class="c_input--longest"
          :placeholder="$t(inputList[1].placeholder)"
          type="password"
          @keydown="$event.keyCode === 13 ? $event.preventDefault() : false"
          name="new_password"
          v-model="inputList[1].input"
          @blur="checkValidte(inputList[1])"
          :class="{note: errors.new}"
        ></el-input>
        <!-- (?=.*[0-9])(?=.*[a-zA-Z])(?!.*([0-9a-zA-Z])\1{3,}) -->
        <div v-if="errors.new">
          <i class="el-icon-warning text-tomato"></i>
          <span class="text-tomato">{{ $t('sysmsg_reg_pwd_err6to12') }}</span>
        </div>
      </div>
    </div>

    <div class="form__field">
      <div class="gy_form__title">{{ $t(inputList[2].text) }}：</div>
      <div>
        <el-input
          class="c_input--longest"
          :placeholder="$t(inputList[2].placeholder)"
          type="password"
          @keydown="$event.keyCode === 13 ? $event.preventDefault() : false"
          name="repeat_password"
          v-model="inputList[2].input"
          @blur="checkValidte(inputList[2])"
          :class="{'is-danger': errors.repeat}"
        ></el-input>
        <div v-if="errors.repeat">
          <i class="el-icon-warning text-tomato"></i>
          <span class="text-tomato">{{ $t('sysmsg_pwd_check_diff') }}</span>
        </div>
      </div>
    </div>
    <div class="u_t--center u_m--t20 u_w--100p">
      <button
        type="button"
        class="c_button--huge userbtn-bg--submit j_btn-txt--primary u_w--100p"
        style="width: calc(100% - 20px)"
        :disabled="!validated"
        @click.stop.prevent="onSubmit"
        v-loading="loading"
      >
        <span class="c_button__text">{{ $t('btn_submit') }}</span>
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import {ElInput, ElMessage} from 'element-plus';
import {IPasswordResult} from '@/model/password';
import {setPassword} from '@/services/server/wap/password';

@Options({
  components: {
    ElInput
  },

  props: {
    type: {
      type: [String, Number],
      default: 'updating'
      // 0 or 'setting'
      // 1 or 'updating' (忘記密碼)
    },
    showClose: {
      default: false
    },
    showTitle: {
      default: true
    },
    buttonType: {
      type: [String, Number],
      default: 0
      // 0 确认
      // 1 上一步 提交
      // 2
    },
    secret: {
      type: String,
      default: ''
    }
  },
  methods: {
    onClear() {
      this.inputList.forEach((v: {input: string}) => (v.input = ''));
    },
    async onSubmit() {
      if (!this.validated) {
        const ans2 = this.inputList[1].input;
        const ans3 = this.inputList[2].input;
        if (ans2 !== ans3 && ans2 && ans3) {
          const msg = this.$t('sysmsg_pwd_check_diff');
          this.$emit('errors', msg);
          ElMessage({type: 'error', message: msg});
          return false;
        }
        return false;
      }
      const ans1 = this.inputList[0].input;
      const ans2 = this.inputList[1].input;
      this.loading = true;
      let data: {
        message?: string;
        error?: string;
      } = {};
      if (!this.isSetting) {
        const payload = {
          old_password: ans1,
          new_password: ans2
          // confirm_password: ans3
        };
        this.$emit('submit', payload);
        data = await setPassword.updated<IPasswordResult>(payload);
      }
      this.loading = false;
      // console.log('debug: onSubmit -> data', data);
      let message = '';
      if (data instanceof Error) {
        message = data.message;
        ElMessage({
          type: 'error',
          duration: 3000,
          message
        });
      } else {
        message = data.message || '';
        this.$emit('close');
        ElMessage({
          type: 'success',
          duration: 3000,
          message: message
        });
        this.onClear();
      }

      // this.loading = false
    },
    toggleType(key: string) {
      this.showPassword[key] = !this.showPassword[key];
    },
    onClose(e: boolean) {
      this.$emit('close', e);
    },
    onPrestep(e: boolean) {
      this.$emit('prestep', e);
    },
    checkValidte(item: {text: string; placeholder: string; input: string; key: string}) {
      if (!this.validationRules.passwordRule.test(item.input)) {
        ElMessage({
          message: this.$t(this.validationRules.passwordError),
          type: 'error'
        });
        return false;
      }
      if (item.key === 'repeat') {
        const ans2 = this.inputList[1].input;
        const ans3 = this.inputList[2].input;
        if (ans2 !== ans3 && ans2 && ans3) {
          const msg = this.$t('sysmsg_pwd_check_diff');
          this.$emit('errors', msg);
          ElMessage({type: 'error', message: msg});
          return false;
        }
      }
      return false;
    }
  },
  computed: {
    isSetting() {
      return this.type === 0 || this.type === 'setting';
    },
    filteredList() {
      if (this.isSetting) {
        return this.inputList.slice(1, 3);
      } else {
        return this.inputList;
      }
    },
    validated() {
      const ans1 = this.inputList[0].input;
      const ans2 = this.inputList[1].input;
      const ans3 = this.inputList[2].input;
      if (/^.{6,12}$/.test(ans2) && /^.{6,12}$/.test(ans1) && /^.{6,12}$/.test(ans3)) {
        return true;
      }
      if (/^.{6,12}$/.test(ans2) && ans2 === ans3) {
        if (!this.isSetting) {
          if (/^.{6,12}$/.test(ans1)) {
            return true;
          }
        } else {
          return true;
        }
      }
      if (/^.{6,12}$/.test(ans2) && /^.{6,12}$/.test(ans3)) {
        if (ans2 === ans3 && ans2 && ans3) {
          const msg = this.$t('sysmsg_pwd_check_diff');
          this.$emit('errors', msg);
        }
      }
      return false;
    },
    validationRules() {
      return {
        nameRule: /[\d|a-z|A-Z]{6,12}/,
        passwordRule: /[\d|a-z|A-Z]{6,12}/,
        nameError: this.$t('ui_no_special_symbols_are_allowed', [6, 12]),
        passwordError: this.$t('ui_the_password_must_be_english_letters', [6, 12]),
        emailRule: /^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/,
        emailError: this.$t('ui_text_069'),
        lineRule: /(^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$|[A-Za-z.-_]{3,}|\d{7,11})/,
        lineError: this.$t('ui_text_070'),
        qqRule: /(^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$|^[1-9]\d{4,9}$|\d{7,11})/,
        qqError: this.$t('ui_text_071'),
        wechatRule: /(^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$|^[a-zA-Z]{1}[-_a-zA-Z0-9]{5,19}$|\d{7,11})/,
        wechatError: this.$t('ui_text_072'),
        zaloRule: /(\d{7,11})/,
        zaloError: this.$t('ui_text_073'),
        skypeRule: /^[^\u4e00-\u9fa5]+$/,
        skypeError: this.$t('ui_text_074')
      };
    }
  }
})
export default class SetPassword extends Vue {
  showPassword = {
    old: false,
    new: false,
    repeat: false
  };
  loading = false;
  selecting = -1;
  inputList = [
    {
      text: 'ui_old_pwd',
      placeholder: 'sysmsg_reg_pwd_err6to12',
      input: '',
      key: 'old'
    },
    {
      text: 'ui_input_new_pwd',
      placeholder: 'sysmsg_reg_pwd_err6to12',
      input: '',
      key: 'new'
    },
    {
      text: 'ui_check_pwd',
      placeholder: 'ui_input_new_pwd_again',
      input: '',
      key: 'repeat'
    }
  ];
  errors = {
    old: false,
    new: false,
    repeat: false
  };

  mounted() {
    console.log('mounted');
  }
}
</script>
<style lang="scss" scoped>
.password-container {
  font-size: 16px;
  font-weight: 400;
}
.form__field {
  display: flex;
  height: 60px;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  .gy_form__title {
    padding-top: 5px;
    text-align: end;
    min-width: 100px;
  }
}
.c_input--longest {
  width: 100%;
  min-width: 270px;
}

.c_input--longest .el-input__inner:valid {
  border-color: transparent;
  color: #666666;
}
.c_input--longest .el-input__inner {
  background-color: #f5f5f5;
}
.el-input__inner::placeholder {
  color: #c0c4cc;
}
[class*='c_input'] {
  border-radius: 5px;
}
[class*='c_input'] button,
[class*='c_input'] > div,
[class*='c_input'] input,
[class*='c_input'] select,
[class*='c_input'] textarea,
[class*='c_input'] .c_input__inner {
  border-radius: inherit;
  width: inherit;
}
[class*='c_button']:not(.c_button__text)[class*='--huge'] {
  font-size: 18px;
  line-height: 25px;
  padding: 4px 12px;
  min-width: 120px;
}
[class*='c_button']:not(.c_button__text) {
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
  font: 14px 'Open Sans', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, 'PingFang SC',
    'Hiragino Sans GB', 'Heiti SC', 'WenQuanYi Micro Hei', sans-serif;
  line-height: 19px;
  overflow: hidden;
  padding: 8px 25px;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: middle;
  -webkit-box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  &[disabled] {
    cursor: not-allowed;
    background-color: #e8e8e8;
    color: #999999;
  }
}
.userbtn-bg--submit {
  background-color: #1f55f0;
  color: #ffffff;
}
.u_w--100p {
  width: 100%;
}
.u_m--t20 {
  margin-top: 20px;
}
</style>
