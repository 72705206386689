import doFetch from '../../fetch';
const ACCPATH = '/api/acc/front';

// password: {
//   /**1.1.5 會員更新密碼 */
//   update: ApiFn('users/update_password'),
// },
const actions = {
  updated: async <T>(data: object) =>
    await doFetch<T>(`${ACCPATH}/v1/users/update_password`, 'POST', data)
};

export const setPassword = actions;
